import React from "react";
import { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/routes/styles/core.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import UserService from "../src/services/UserService";
import "bootstrap/dist/css/bootstrap.min.css";
import { store } from "./store";
import { Provider } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Timer from "./components/Timer";
const renderApp = () =>
  ReactDOM.createRoot(document.getElementById("root")).render(
//    <React.StrictMode>
      <BrowserRouter>
        {/* <Profiler id="App" onRender={onRender}> */}
        <Provider store={store}>
          <App />
        </Provider>

        {/* </Profiler> */}
      </BrowserRouter>
  //  </React.StrictMode>
  );
  const MainApp = () => {
    const [showTimer, setShowTimer] = useState(true);
  
    const handleTimerFinish = () => {
      setShowTimer(false);
      UserService.initKeycloak(renderApp);
    };
  
    const getTargetDate = () => {
      const now = new Date("2024-08-01 00:00:00");
      return now;
    };
  
    return showTimer ? (
      <Timer targetDate={getTargetDate()} onFinish={handleTimerFinish} />
    ) : (
      <div>Loading login...</div>
    );
  };
  
  ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <MainApp />
    </React.StrictMode>
  );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
