import { ErrorMessage, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineDownload } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { array, object, string } from "yup";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import CommonModal from "../../../components/CommonModal";
import CommonPageTitle from "../../../components/CommonPageTitle";
import CommonVesselDetails from "../../../components/CommonVesselDetails";
import SelectBox from "../../../components/Selectbox";
import TextArea from "../../../components/TextArea";
import {
  useAddCarvingMutation,
  useApproveCarvingProcessMutation,
  useLazyFetchCarvingByIdQuery,
  useRejectCarvingProcessMutation,
  useSubmitCarvingProcessMutation,
  useUpdateCarvingMutation,
} from "../../../services/modules/carving";
import { useLazyFetchVesselbyIdQuery } from "../../../services/modules/vesselowner/preapproval";
import { useLazyFetchAllVesselListQuery } from "../../../services/modules/PreApprovals";

import {
  PORT_CONSERVATOR,
  RA,
  VESSEL_OWNER,
  chargeListType,
} from "../../../utils/constants";
import { getRole } from "../../../utils/utility";
import FileUploader from "../../../components/FileUploader";
import { useLazyDownloadFileQuery } from "../../../services/modules/common/file";
import { CheckoutPage } from "../../../components/CheckoutPage";
import Remarks from "../../../components/Remarks";
import Loader from "../../../components/Loader";

function CarvingDetails({ ...props }) {
  const role = getRole();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { mode } = state || {};

  const onHandleClickBack = () => {
    navigate("/carving-requests");
  };
  const onHandleClickBackCheckout = () => {
    window.location.href = "/carving-requests";
  };

  const [vesselId, setVesselId] = useState(state?.vesselId || null);
  const [carvingId, setCarvingId] = useState(state?.carvingId || null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [remarks, setRemarks] = useState([]);

  const [modalState, setModalState] = useState({
    show: false,
    title: "",
    description: "",
    buttonText: "OK",
    isSuccess: false,
  });

  const [
    addCarving,
    {
      data: addCarvingData,
      isSuccess: isAddCarvingSuccess,
      isError: isAddCarvingError,
      error: addCarvingError,
      isLoading: isAddCarvingLoading,
    },
  ] = useAddCarvingMutation();

  const [
    updateCarving,
    {
      data: updateCarvingData,
      isSuccess: isUpdateCarvingSuccess,
      isError: isUpdateCarvingError,
      error: updateCarvingError,
      isLoading: isUpdateCarvingLoading,
    },
  ] = useUpdateCarvingMutation();

  const [
    submitCarvingProcess,
    {
      data: submitCarvingProcessData,
      isSuccess: isSubmitCarvingProcessSuccess,
    },
  ] = useSubmitCarvingProcessMutation();

  //const [fetchRegistration, { data: preApprovalListData }] =
  //  useLazyFetchRegistrationQuery();
  const [fetchAllVesselList, { data: preApprovalListData }] =
    useLazyFetchAllVesselListQuery();

  const [
    fetchVesselbyId,
    { data: vesselDataOfId, isSuccess: isVesselDataOfIdSuccess },
  ] = useLazyFetchVesselbyIdQuery();

  const [
    fetchCarvingById,
    { data: carvingDataOfId, isSuccess: isCarvingDataOfIdSuccess },
  ] = useLazyFetchCarvingByIdQuery();

  const [downloadFile] = useLazyDownloadFileQuery();

  // Fetch vessel list for choosing vessel in new carving request
  useEffect(() => {
    if (role === VESSEL_OWNER && mode !== "view" && mode !== "edit")
      fetchAllVesselList({
        // Search params to filter out drafted and submitted vessel list
        searchParams: "vesselStatus=Registration Approved",
      });
  }, [fetchAllVesselList, mode, role]);

  // Fetch vessel information
  useEffect(() => {
    if (vesselId) fetchVesselbyId({ id: vesselId });
  }, [fetchVesselbyId, vesselId]);

  // After vessel information is fetched
  useEffect(() => {
    if (!isVesselDataOfIdSuccess) return;
    if (vesselDataOfId?.result.length > 0) {
      const { port, carvingId } = vesselDataOfId?.result[0];
      if (role === VESSEL_OWNER) setCarvingId(carvingId);
      formik.setFieldValue("portId", port._id);
    }
  }, [isVesselDataOfIdSuccess, role, vesselDataOfId]);

  // Set carving id after carving request is created
  useEffect(() => {
    if (!isSubmitting) return;
    let carvingProcessId;
    if (isAddCarvingSuccess) {
      carvingProcessId = addCarvingData?.result._id;
      setCarvingId(addCarvingData?.result._id);
    } else if (isUpdateCarvingSuccess) {
      carvingProcessId = updateCarvingData?.result._id;
      setCarvingId(updateCarvingData?.result._id);
    }
    if (carvingProcessId) submitCarvingProcess({ id: carvingProcessId });
  }, [
    addCarvingData,
    isAddCarvingSuccess,
    isSubmitting,
    submitCarvingProcess,
    isUpdateCarvingSuccess,
    updateCarvingData,
  ]);

  const carvingProcessId = useMemo(() => {
    if (!isSubmitting) return;
    let carvingProcessId;
    if (isAddCarvingSuccess) {
      carvingProcessId = addCarvingData?.result._id;
      setCarvingId(addCarvingData?.result._id);
    } else if (isUpdateCarvingSuccess) {
      carvingProcessId = updateCarvingData?.result._id;
      setCarvingId(updateCarvingData?.result._id);
    }
    return carvingProcessId;
  }, [isSubmitting, isAddCarvingSuccess, isUpdateCarvingSuccess]);

  const initialValues =
    role === VESSEL_OWNER
      ? {
          vesselId: vesselId,
          portId: "",
          photos: ["", "", ""],
        }
      : {
          vesselId: "",
          remarks: "",
        };

  const validationSchema =
    role !== VESSEL_OWNER
      ? object().shape({
          remarks: string()
            .required("Remarks is required")
            .matches(
              /.*\S.*/,
              "Remark cannot be empty or consist of only spaces."
            ),
        })
      : object().shape({
          vesselId: string().required("Please select a vessel"),
          portId: string(),
          photos: array().of(
            string()
              .required("Photos is required")
              .min(3, "Please select all the photos")
          ),
        });

  const onSubmit = (values) => {
    if (values.action === "approve") {
      setShouldRedirect(true);
      handleApprove(values);
    } else if (values.action === "reject") {
      setShouldRedirect(true);
      handleReject(values);
    } else {
      setIsSubmitting(true);
      submitCarving();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const isTouched = (fieldName, formik) => {
    const isCondition = formik.touched[fieldName] && formik.errors[fieldName];
    return isCondition ? true : false;
  };

  const saveAsDraft = async () => {
    if (carvingId) {
      updateCarving({
        id: carvingId,
        data: formik.values,
      });
    } else {
      await addCarving({ data: formik.values });
    }
  };

  async function submitCarving() {
    await saveAsDraft();
    // submitCarvingProcess({ id: carvingId });
  }

  useEffect(() => {
    if (isAddCarvingSuccess) {
      if (!isSubmitting)
        setModalState({
          title: addCarvingData?.message,
          show: true,
          isSuccess: true,
        });
    } else if (isAddCarvingError) {
      setModalState({
        title: addCarvingError?.data?.message,
        show: true,
        isSuccess: false,
      });
    }
  }, [
    isAddCarvingSuccess,
    addCarvingData,
    addCarvingError,
    isAddCarvingError,
    isSubmitting,
  ]);

  useEffect(() => {
    if (isUpdateCarvingSuccess) {
      if (!isSubmitting)
        setModalState({
          title: updateCarvingData?.message,
          show: true,
          isSuccess: true,
        });
      setShouldRedirect(true);
    } else if (isUpdateCarvingError) {
      setModalState({
        title: updateCarvingError?.message,
        show: true,
        isSuccess: false,
      });
    }
  }, [
    isUpdateCarvingSuccess,
    updateCarvingData,
    updateCarvingError,
    isUpdateCarvingError,
    isSubmitting,
  ]);

  useEffect(() => {
    if (!isSubmitCarvingProcessSuccess) return;
    setModalState({
      title: submitCarvingProcessData?.message,
      show: true,
      isSuccess: true,
    });
  }, [submitCarvingProcessData, isSubmitCarvingProcessSuccess]);

  // RA Approve and Reject carving api
  const [
    rejectCarvingProcess,
    {
      isSuccess: isRejected,
      isError: hasRejectFailed,
      data: rejectSuccessData,
      error: rejectFailedData,
    },
  ] = useRejectCarvingProcessMutation();

  const [
    approveCarvingProcess,
    {
      isSuccess: isApproved,
      isError: hasApproveFailed,
      data: approveSuccessData,
      error: approveFailedData,
    },
  ] = useApproveCarvingProcessMutation();

  // Success messages
  useEffect(() => {
    if (isApproved) {
      setModalState({
        show: true,
        isSuccess: true,
        title: approveSuccessData.message,
      });
    } else if (isRejected) {
      setModalState({
        show: true,
        isSuccess: true,
        title: rejectSuccessData.message,
      });
    }
  }, [isApproved, isRejected, approveSuccessData, rejectSuccessData]);

  // Error messages
  useEffect(() => {
    if (hasApproveFailed) {
      setModalState({
        show: true,
        isSuccess: false,
        title: approveFailedData.data.message,
      });
    } else if (hasRejectFailed) {
      setModalState({
        show: true,
        isSuccess: false,
        title: rejectFailedData.data.message,
      });
    }
  }, [hasApproveFailed, hasRejectFailed, approveFailedData, rejectFailedData]);

  // Handle approval and rejection
  function handleApprove(values) {
    const { remarks } = values;
    const postData = {
      remarks: [
        {
          remark: remarks,
          action: "approve",
        },
      ],
    };
    approveCarvingProcess({ data: postData, id: carvingId });
  }
  function handleReject(values) {
    const { remarks } = values;
    const postData = {
      remarks: [
        {
          remark: remarks,
          action: "reject",
        },
      ],
    };
    rejectCarvingProcess({ data: postData, id: carvingId });
  }

  // Store uploaded photos
  const [uploadedPhotos, setUploadedPhotos] = useState([]);

  // Fetch carving details
  useEffect(() => {
    if (!carvingId) return;
    fetchCarvingById({ id: carvingId });
  }, [fetchCarvingById, carvingId]);

  const [isPaymentComplete, setIsPaymentComplete] = useState(false);

  useEffect(() => {
    if (!carvingDataOfId) return;
    const remark = carvingDataOfId?.result?.remarks;
    setRemarks(remark);
    const paymentStatus = carvingDataOfId?.result?.paymentStatus;
    setIsPaymentComplete(paymentStatus === "captured");
  }, [carvingDataOfId]);

  // Set uploaded file names array
  useEffect(() => {
    if (!isCarvingDataOfIdSuccess) return;
    const photos = carvingDataOfId?.result?.photos;
    if (photos) {
      setUploadedPhotos(photos);
      formik.setFieldValue("photos", photos);
    }
  }, [carvingDataOfId, isCarvingDataOfIdSuccess]);

  // Handle file download
  const handleDownloadClick = (fileName) => {
    downloadFile({ fileName })
      .then((response) => {
        window.open(response.data, "_blank");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const [showPayment, setShowPayment] = useState(false);

  if (showPayment)
    return (
      <CheckoutPage
        applicationType={chargeListType.carving}
        applicationId={carvingProcessId}
        receipt={carvingProcessId}
        description="Carving Request"
        onSuccess={onHandleClickBackCheckout}
        onFailure={onHandleClickBackCheckout}
      />
    );

  if (isAddCarvingLoading || isUpdateCarvingLoading) return <Loader />;

  return (
    <Container className="dashboard-container">
      <Breadcrumb crumbs={props.crumbs} />
      <CommonPageTitle
        title="Back"
        arrowback={true}
        handleClickBack={onHandleClickBack}
      />
      <Container>
        <CommonModal
          {...modalState}
          buttonText="OK"
          description=""
          handleClose={() => {
            setModalState((prev) => {
              return { ...prev, show: false };
            });

            if (modalState.isSuccess && (shouldRedirect || isSubmitting))
              onHandleClickBack();
          }}
        />
        <FormikProvider value={formik}>
          <Row>
            {role === VESSEL_OWNER && mode !== "view" && mode !== "edit" ? (
              <Row className="survey-row">
                <Col md={4}>
                  <SelectBox
                    label="Choose Vessel"
                    name="vesselName"
                    required={true}
                    isRequired={true}
                    isIdSelect={true}
                    onChange={(selectedVessel) => {
                      formik.handleChange(selectedVessel);
                      setVesselId(selectedVessel.target.value);
                    }}
                    touched={isTouched("", formik)}
                    error={formik.errors.vesselId}
                    onBlur={formik.handleBlur}
                    options={preApprovalListData?.result?.data ?? []}
                  />
                </Col>
              </Row>
            ) : null}
            {vesselId ? (
              <CommonVesselDetails
                vesselId={vesselId}
                registrationId={
                  vesselId
                    ? vesselDataOfId?.result[0]?.registartionId || null
                    : null
                }
                
              />
            ) : null}
            {(role === RA || role === PORT_CONSERVATOR) && vesselId ? (
              <Remarks remarks={remarks} />
            ) : null}

            <Row className="mt-4">
              {role === VESSEL_OWNER && vesselId ? (
                <Col md={4}>
                  <h4>Upload Photos</h4>
                  {formik.values.photos.map((photo, index) => (
                    <Row className="pt-3">
                      <label className="file-selector-label">
                        <span className="required-indicator">
                          Choose Photo {index + 1}
                        </span>
                      </label>
                      <FileUploader
                        value={photo === "" ? [] : [photo]}
                        name={`photos[${index}]`}
                        onUpload={(fileName) => {
                          formik.setFieldValue(`photos[${index}]`, fileName[0]);
                        }}
                        onDelete={(fileName) => {
                          console.log(fileName);
                          formik.setFieldValue(`photos[${index}]`, "");
                        }}
                        showDelete={mode !== "view"}
                        disabled={mode === "view"}
                        accept="image/*"
                      />
                      <ErrorMessage name={`photos[${index}]`}>
                        {(error) => (
                          <span className="error-message">{error}</span>
                        )}
                      </ErrorMessage>
                    </Row>
                  ))}
                </Col>
              ) : null}

              {(role === RA || role === PORT_CONSERVATOR) && vesselId ? (
                <Col md={4}>
                  <h4>Download Photos</h4>
                  {uploadedPhotos.map((fileName, key) => (
                    <Row className="pt-3" key={key}>
                      <Col md={6}>
                        <span>Photo {key + 1}</span>
                      </Col>
                      <Col md={6}>
                        <button
                          className="d-flex align-items-center justify-content-center gap-2 border-0 bg-transparent outline-none btn_back_text"
                          onClick={() => {
                            handleDownloadClick(fileName);
                          }}
                        >
                          <AiOutlineDownload />
                          <span>Download</span>
                        </button>
                      </Col>
                    </Row>
                  ))}
                </Col>
              ) : null}
              {role !== VESSEL_OWNER && vesselId ? (
                <Col md={4} className="offset-md-4">
                  <TextArea
                    label="Remarks"
                    required={true}
                    id="remarks"
                    name="remarks"
                    type="text"
                    row={3}
                    onChange={formik.handleChange}
                    value={formik.values.remarks}
                    touched={isTouched("remarks", formik)}
                    error={formik.errors.remarks}
                  />
                </Col>
              ) : null}
            </Row>
            <Container className="container mt-4">
              <Row className="button-container">
                {role === VESSEL_OWNER && vesselId && mode !== "view" ? (
                  <Col className="btn-col address-button d-flex justify-content-center gap-2">
                    <Button
                      label="Save as Draft"
                      className="btn-outline shadow-none"
                      type="button"
                      onClick={() => {
                        saveAsDraft();
                      }}
                    />
                    {/* <Button
                      label="Preview"
                      className="btn-outline shadow-none"
                      type="button"
                    /> */}
                    <Button
                      label={"Submit"}
                      className="btn-default shadow-none"
                      type="submit"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    />
                  </Col>
                ) : null}
                {(role === RA || role === PORT_CONSERVATOR) && vesselId ? (
                  <Col className="btn-col address-button d-flex justify-content-center gap-2">
                    <Button
                      label="Reject"
                      className="btn-danger btn-outline shadow-none"
                      type="button"
                      onClick={() => {
                        formik.setFieldValue("action", "reject");
                        formik.handleSubmit();
                      }}
                    />
                    <Button
                      label="Approve"
                      className="btn-default shadow-none"
                      type="button"
                      onClick={() => {
                        formik.setFieldValue("action", "approve");
                        formik.handleSubmit();
                      }}
                    />
                  </Col>
                ) : null}
              </Row>
            </Container>
          </Row>
        </FormikProvider>
      </Container>
    </Container>
  );
}

export default CarvingDetails;
