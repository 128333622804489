// ./components/Timer.js
import React, { useEffect, useState } from "react";
import FlipClock from 'x-react-flipclock'
import { Col, Container, Row } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import logo from '../assets/coming_soon_image.png';

const Timer = ({ targetDate, onFinish }) => {

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};
console.log("difference", difference);
    if (difference <= 0) {
      onFinish();
    } 
  };
  const centeredCard = {
    padding: '60px',
  };

  const mainDiv = {
    backgroundImage: `url(${logo}`,
    backgroundSize: '100%'
  }
  calculateTimeLeft()
  return (
    <div className="d-flex align-items-center  
    justify-content-center vh-100" style={mainDiv}>
      <Card>
        <Card.Body>
          <div style={centeredCard}>
            <FlipClock
              type="countdown"
              count_to="2024-08-01 00:00:00"
            />
          </div>
        </Card.Body>
      </Card>

    </div>
  );
};

export default Timer;
