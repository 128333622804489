import React from 'react'
import "./style.css"
function ScrollBar() {
return (
<div class="marquee">
<h2 class="marquee__text" data-direction="rtl">
ആലപ്പുഴ പോർട്ട്‌ ഓഫ് രജിസ്റ്ററിയിൽ ഹൗസ് ബോട്ടുകളുടെ രജിസ്ട്രെഷൻ ബഹുമാനപ്പെട്ട ഹൈക്കോടതി ഇക്കാര്യത്തിൽ പുറപ്പെടുവിച്ചിട്ടുള്ള സ്റ്റേ ഉത്തരവ് പിൻവലിക്കുന്ന മുറയ്ക്ക് മാത്രമേ പുനരാരംഭിക്കുകയുള്ളൂ
</h2> </div>
)
}

export default ScrollBar
